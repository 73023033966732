import { mediaDel } from "../api/media";
export default {
  data() {
    return {
      openVideoDialog: false, // 视频弹窗
      videoData: null, // 视频弹窗数据
      openMusicDialog: false, // 音频弹窗
      musicData: null, // 音频弹窗数据
    }
  },
  computed: {
    //勾选组
    checkedCities: {
      get() {
        return this.selectArr;
      },
      set(val) {
        this.$emit("update:selectArr", val);
      },
    },
    //面包屑导航数组
    breadcrumbArr() {
      return this.$store.getters["media/breadcrumbArr"];
    }
  },
  methods: {
    // 选中
    handleCheckedCitiesChange(value) {
      this.$emit("update:selectArr", value);
    },
    // 打开视频弹窗
    openVideo(data) {
      this.$store.dispatch('media/openMediaVideoDialog', data);
    },
    // 打开音频弹窗
    openMusic(data) {
      this.$store.dispatch('media/openMediaMusicDialog', data);
    },
    // 单个删除
    oddDelete(data) {
      this.$msgbox
        .confirm(`您是否要删除 ${data.title} 该文件？`, "提示", {
          type: "warning",
          confirmButtonText: "确定",
          cancelButtonText: "取消",
        })
        .then((res) => {
          mediaDel({ ids: [data.id] }).then((res) => {
            //更新
            this.$store.commit('media/setIsRefresh', true)
            //提示
            this.$message({
              type: "success",
              message: "删除成功!",
            });
          });
        })
        .catch((err) => {
          // console.log(err);
        });
    },
    // 双击进入文件夹
    enterFolder(data) {
      if (data.type === "folder") {
        // 更新筛选选中的目录
        this.$store.commit("media/setCurrentFolder", {
          id: data.id,
          title: data.title
        });
        // 更新
        this.$store.dispatch('media/updateMediaView', {
          folder_id: data.id
        })
        //更新面包屑导航
        const newBA = Array.from(this.breadcrumbArr);
        newBA.push(data);
        this.$store.commit("media/setBreadcrumbArr", newBA);
      }
    },
    // 文件详情
    openDetail(id) {
      this.$store.dispatch('media/openMediaDetailDialog', {id})
    },
    // 图片裁剪
    openCrop(imgSrc, pid) {
      this.$store.dispatch('crop/openMediaCropDialog', {imgSrc, pid, mediaKey: ''})
    }
  }
}
