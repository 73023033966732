<template>
  <div class="view-thumbnail">
    <el-checkbox-group class="view-thumbnail-list" v-model="checkedCities"
      @change="handleCheckedCitiesChange">
      <div class="view-thumbnail-item" v-for="v in viewData" :key="v.id">
        <list-media-item :label="v.id" :item="v" size="big" multiple preview @enter-folder="enterFolder(v)"
          @preview-video="openVideo(v)" @preview-audio="openMusic(v)" @delete="oddDelete(v)"
          @open-crop="openCrop(v.url, v.pid)" @open-detail="openDetail(v.id)"></list-media-item>
      </div>
    </el-checkbox-group>
  </div>
</template>
<script>
import view from "../mixins/view";
import ListMediaItem from "../../../base/components/Media/ListMediaItem";

export default {
  components: { ListMediaItem },
  mixins: [view],
  props: {
    viewData: {}, //数据
    selectArr: {}, //勾选项id数组
  },
  data() {
    return {};
  },
};
</script>
<style lang="scss" scoped>
.view-thumbnail {
  .view-thumbnail-list {
    width: 100%;
    overflow-y: auto;
    display: grid;
    /*grid-template-columns: repeat(auto-fill, 170px);*/
    grid-template-columns: repeat(6, minmax(212px, 17%));
    /*grid-template-rows: repeat(2, 1fr);*/
    grid-row-gap: 16px;
    grid-column-gap: 12px;

    .el-radio__input {
      z-index: 1;
    }

    ::v-deep.el-radio__input {
      z-index: 1;
    }
  }

  @media (max-width: 1640px) {
    .view-thumbnail-list {
      /*height: 530px;*/
      grid-template-columns: repeat(5, minmax(120px, 20%));
      /*grid-template-rows: repeat(3, 1fr);*/
    }
  }

  @media (max-width: 1340px) {
    .view-thumbnail-list {
      /*height: 530px;*/
      grid-template-columns: repeat(4, minmax(180px, 25%));
      /*grid-template-rows: repeat(3, 1fr);*/
    }
  }
  // 图片弹窗
  ::v-deep .el-image-viewer__wrapper {
    .el-icon-circle-close {
      color: #fff;
    }
  }
}
</style>
