<template>
  <LayoutFilter :on-filter="ok" :on-reset="resetForm" :toggleCollapseEnable="false" class="media-filter">
    <el-form :model="form" :rules="formRules" ref="mediaFilterForm" label-width="70px" size="medium"
      class="filter-from">

      <!-- 查看&格式 -->
      <div class="filter-item">
        <el-form-item label="查看类型：" prop="only_myself">
          <el-select v-model="form.only_myself" placeholder="请选择查看类型">
            <el-option v-for="item in only_myselfOptions" :key="item.value" :label="item.label"
              :value="item.value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="查看格式：" prop="type">
          <el-select v-model="form.type" placeholder="请选择格式">
            <el-option v-for="item in typeOptions" :key="item.type" :label="item.name" :value="item.type">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="搜索内容：" prop="keyword">
          <el-input v-model="form.keyword" placeholder="请输入搜索内容"></el-input>
        </el-form-item>
        <!-- 文件夹 -->
        <!--        <el-form-item label="文件夹：" prop="folder_id">-->
        <!--          <el-select v-model="form.folder_id" placeholder="请选择文件夹">-->
        <!--            <el-option v-for="op in folderOptions" :key="op.id" :label="op.title" :value="op.id"></el-option>-->
        <!--          </el-select>-->
        <!--        </el-form-item>-->
      </div>
      <!-- 搜索 -->
      <!-- <div class="filter-item">
        <el-form-item label="搜索内容：" prop="keyword">
          <el-input v-model="form.keyword" placeholder="请输入搜索内容"></el-input>
        </el-form-item>
      </div> -->
      <div class="filter-item">
        <!-- 创建时间 -->
        <DatePeriodPicker label="上传时间：" start-prop="create_start_time"
          :start-time.sync="form.create_start_time" end-prop="create_end_time"
          :end-time.sync="form.create_end_time" />
      </div>
    </el-form>
  </LayoutFilter>
</template>
<script>
import LayoutFilter from "@/base/layout/LayoutFilter";
import DatePeriodPicker from "@/base/components/Form/DatePeriodPicker";
import { mediaTypes } from "../api/media";
//media vuex
import { createNamespacedHelpers } from "vuex";

const { mapGetters } = createNamespacedHelpers("media");
export default {
  //更新视图回调
  inject: ["updateMedia"],
  data() {
    return {
      form: {
        create_start_time: -1, //开始时间
        create_end_time: -1, //结束时间
        only_myself: 0, //查看类型
        folder_id: -1, //文件夹id
        type: "all", //格式
        keyword: "", //搜索
      },
      formRules: {},
      only_myselfOptions: [
        { label: "全部", value: 0 },
        { label: "只看自己", value: 1 },
      ],
      typeOptions: [], //格式选项数组
    };
  },
  computed: {
    ...mapGetters(["currentFolder", "folderOptions"]),
  },
  methods: {
    // 重置表单
    resetForm() {
      this.$refs["mediaFilterForm"].resetFields();
      this.$store.commit("media/setBreadcrumbArr", [
        { id: -1, title: "全部文件" },
      ]);
      this.ok()
    },
    //筛选
    ok() {
      if (this.updateMedia) this.updateMedia({ ...this.form, page: 1 });
    },
    // 获取文件格式选项数组
    getMediaTypes() {
      mediaTypes().then((res) => {
        const { data } = res;
        this.typeOptions = [{ name: "全部", type: "all" }].concat(data);
      });
    },
  },
  created() {
    //文件夹默认选中
    this.form.folder_id = this.currentFolder.id;
    //获取文件夹信息
    this.$store.dispatch("media/getFoldersData");
    //获取文件格式选项数组
    this.getMediaTypes();
  },
  watch: {
    // 选中文件夹发生变化
    "form.folder_id"(val) {
      let id = val || -1;
      this.$store.commit(
        "media/setCurrentFolder",
        this.folderOptions.find((item) => item.id === id)
      );
    },
    // vuex变化更新表单选中
    folder_id(val) {
      if (this.form.folder_id != val) {
        this.form.folder_id = val;
      }
    },
  },
  components: {
    LayoutFilter,
    DatePeriodPicker,
  },
};
</script>
