<template>
  <div class="media-label cursor-pointer"
       :class="[active ? 'active' : '', size]"
       @click="$emit('click')">
    <span class="media-label-name">{{title}}</span>
    <span class="media-label-count"
          v-if="title !== '全部' && num !== undefined">（{{num}}）</span>
  </div>
</template>

<script>
  export default {
    name: "MediaLabel",
    props: {
      num: {
        type: Number,
      },
      title: {
        type: String,
      },
      active: {
        type: Boolean,
        default: false
      },
      // normal | small
      size: {
        type: String,
        default: 'normal'
      }
    },
  }
</script>

<style lang="scss" scoped>
  .media-label {
    display: inline-block;
    font-size: 14px;
    color: #303133;
    line-height: 14px;
    padding: 5px 15px;
    border: 1px solid transparent;
    white-space: nowrap;
    border-radius: 14px;

    &.small {
      border-radius: 12px;
      border: 1px solid #DCDFE6;
      font-size: 12px;
      color: #303133;
      line-height: 12px;
    }

    &.active {
      border: 1px solid $primary;
      color: $primary;
    }
  }
</style>
