<template>
  <div class="view-table">
    <el-checkbox-group v-model="checkedCities" @change="handleCheckedCitiesChange">
      <el-table :data="viewData" style="width: 100%" size="medium" class="media-table">
        <template v-for="th in thead">
          <!-- 勾选 -->
          <el-table-column v-if="th.prop==='select'" :prop="th.prop" :label="th.label"
            :min-width="th.minWidth||''" :key="th.label">
            <template slot-scope="scope">
              <el-checkbox :label="scope.row.id">{{""}}</el-checkbox>
            </template>
          </el-table-column>
          <!-- 操作 -->
          <el-table-column v-else-if="th.prop==='operate'" :prop="th.prop" :label="th.label"
            :min-width="th.minWidth||''" :key="th.label">
            <template slot-scope="scope">
              <div class="btn-group">
                <el-button size="mini" type="warning" v-if="scope.row.type==='image'"
                  @click="openCrop(scope.row.url, scope.row.pid)">裁剪</el-button>
                <el-button size="mini" type="primary" @click="openDetail(scope.row.id)">详情</el-button>
                <el-button size="mini" @click="oddDelete(scope.row)">删除</el-button>
              </div>
            </template>
          </el-table-column>
          <!-- 文件名 -->
          <el-table-column v-else-if="th.prop==='title'" :prop="th.prop" :label="th.label"
            :min-width="th.minWidth||''" :key="th.label">
            <template slot-scope="scope">
              <div class="file-name" @click="useOpenDialog($event,scope.row)"
                @dblclick="enterFolder(scope.row)">
                <div class="content">
                  <!-- 图片 -->
                  <el-image v-if="scope.row.type==='image'" :src="scope.row.url" :alt="scope.row.title"
                    :preview-src-list="[scope.row.url]" fit="contain"></el-image>
                  <!-- 视频 -->
                  <el-image v-if="scope.row.type==='video'" :src="videoIcon" :alt="scope.row.title"
                    fit="contain"></el-image>
                  <!-- 附件 -->
                  <el-image v-if="scope.row.type==='file'" :src="fileIcon" :alt="scope.row.title"
                    fit="contain"></el-image>
                  <!-- 音频 -->
                  <el-image v-if="scope.row.type==='audio'" :src="musicIcon" :alt="scope.row.title"
                    fit="contain"></el-image>
                  <!-- 文件夹 -->
                  <el-image v-if="scope.row.type==='folder'" :src="folderIcon" :alt="scope.row.title"
                    fit="contain"></el-image>
                  <h2 class="title" onselectstart="return false">{{scope.row.title}}</h2>
                </div>
              </div>
            </template>
          </el-table-column>
          <!-- 普通 -->
          <el-table-column v-else :prop="th.prop" :label="th.label" :min-width="th.minWidth || ''"
            :key="th.label">
          </el-table-column>
        </template>
      </el-table>
    </el-checkbox-group>
  </div>
</template>
<script>
import view from "../mixins/view";
export default {
  mixins: [view],
  props: {
    viewData: {}, //数据
    selectArr: {}, //勾选项id数组
  },
  data() {
    return {
      thead: [
        { label: "勾选", prop: "select", minWidth: 60 },
        { label: "文件名", prop: "title", minWidth: 400 },
        { label: "大小", prop: "file_size", minWidth: 120 },
        { label: "时间", prop: "create_time", minWidth: 200 },
        { label: "操作", prop: "operate", minWidth: 230 },
      ],
      videoIcon: require("../../../base/assets/images/media/icon_video_mini.png"), //视频图标
      fileIcon: require("../../../base/assets/images/media/icon_file.png"), //文件图标
      musicIcon: require("../../../base/assets/images/media/icon_music.png"), //音频图标
      folderIcon: require("../../../base/assets/images/media/icon_folder.png"), //文件夹图标
    };
  },
  methods: {
    // 通用显示弹窗
    useOpenDialog(event, data) {
      const type = data.type;
      switch (type) {
        case "image":
          if (event.target.tagName.toLowerCase() === "h2") {
            event.currentTarget.querySelector(".el-image > img").click();
          }
          break;
        case "video":
          this.openVideo(data);
          break;
        case "audio":
          this.openMusic(data);
          break;
        case "file":
          break;
        case "folder":
          break;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.view-table {
  .media-table {
    .file-name {
      display: inline-block;
      cursor: pointer;
      .content {
        display: flex;
        align-items: center;
        ::v-deep.el-image {
          width: 20px;
          height: 20px;
          margin-right: 5px;
        }
        .title {
          font-weight: normal;
          font-size: 14px;
        }
      }
    }
    .btn-group {
      text-align: right;
    }
  }
  //图片弹窗
  ::v-deep.el-image-viewer__wrapper {
    .el-icon-circle-close {
      color: #fff;
    }
  }
}
</style>
