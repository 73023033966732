<template>
  <div class="media">
    <div class="head">
      <MediaFilter />
    </div>
    <div class="content">
      <div class="head">
        <div class="left btn-group">
          <el-button size="small" type="primary" @click="openUploadDialog=true">
            <i class="el-icon-plus"></i>
            上 传
          </el-button>
          <!--          <el-button size="small" @click="showCreateFolder">新建文件夹</el-button>-->
        </div>
        <!--        <div class="right">-->
        <!--          <el-breadcrumb separator-class="el-icon-arrow-right">-->
        <!--            <el-breadcrumb-item v-for="(item,i) in breadcrumbArr" :key="item.id"-->
        <!--              @click.native="nextFolder(item.id,i)">{{item.title}}</el-breadcrumb-item>-->
        <!--          </el-breadcrumb>-->
        <!--        </div>-->
      </div>
      <div class="body" v-loading="mediaLoading">
        <!-- 展示 -->
        <MediaView :data="mediaData ? mediaData.data:[]" :total="mediaData ? mediaData.total : 0"
          @loading="mediaLoading = $event" />
      </div>
      <div class="footer">
        <!-- 分页 -->
        <Pagination v-if="mediaData" :total="mediaData.total" :page="mediaData.current_page"
          :change="turnPage" :pageSize="18" />
      </div>
    </div>
    <!-- 上传弹窗 -->
    <UploadDialog v-model="openUploadDialog" :onComplete="listenComplete" />
  </div>
</template>
<script>
import { mediaList } from "../api/media";

import UploadDialog from "../../common/components/UploadDialog";

import MediaFilter from "../components/MediaFilter";
import MediaView from "../components/MediaView";
import Pagination from "../../../base/components/Default/Pagination";

// media vuex
import { createNamespacedHelpers } from "vuex";

const { mapGetters } = createNamespacedHelpers("media");

export default {
  provide() {
    return {
      //跨层级注入更新数据
      updateMedia: this.updateMedia,
    };
  },
  data() {
    return {
      mediaData: null, //媒体库数据
      mediaLoading: true, //加载中
      openUploadDialog: false, //上传弹窗
    };
  },
  computed: {
    ...mapGetters([
      "filterConfig", // 筛选条件
      "folder_id", // 进入的文件夹id
      "breadcrumbArr", // 面包屑导航数组
      "isRefresh",
      "otherFilterConfig",
    ]),
  },
  watch: {
    isRefresh(val) {
      if (val) {
        this.updateMedia(this.otherFilterConfig);
      }
    },
  },
  created() {
    this.$store.commit("media/setFilterConfig", {
      page_size: 18, //每页条数（默认：15）
      type: "all", //类型
      page: 1,
    });
    // 获取列表数据
    this.updateMedia()
  },
  methods: {
    listenComplete(e) {
      this.updateMedia({page: 1})
    },
    // 获取媒体库数据
    getMediaListData() {
      this.mediaLoading = true;
      mediaList(this.filterConfig)
        .then((res) => {
          const { data } = res;
          this.mediaData = data;
        })
        .catch((err) => {})
        .finally(() => {
          this.mediaLoading = false;
          this.$store.commit("media/setIsRefresh", false);
        });
    },
    // 翻页
    turnPage(page) {
      this.$store.dispatch("media/updateMediaView", page);
    },
    // 面包屑点击进入指定id目录
    nextFolder(id, index) {
      //更新vuex 面包屑导航
      let newBA = Array.from(this.breadcrumbArr);
      newBA.splice(index + 1);
      this.$store.commit("media/setBreadcrumbArr", newBA);
      // 触发更新
      this.updateMedia({ folder_id: id });
    },
    //更新数据
    updateMedia(data) {
      this.openUploadDialog = false;
      // 更新筛选条件
      if (data) {
        let newFiler = Object.assign({}, this.filterConfig, data);
        //更新vuex
        this.$store.commit("media/setFilterConfig", newFiler);
      }
      // 获取数据
      this.getMediaListData();
    },
  },
  components: {
    MediaFilter,
    UploadDialog,
    MediaView,
    Pagination,
  },
};
</script>
<style lang="scss" scoped>
.media {
  > .head {
    margin-bottom: 24px;
  }

  .content {
    .head {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      margin-bottom: 20px;

      .left {
        &.btn-group {
          button {
            & + button {
              margin-left: 20px;
            }
          }
        }
      }

      .right {
        .el-breadcrumb__item {
          ::v-deep .el-breadcrumb__inner {
            cursor: pointer;

            &:hover,
            &:active {
              color: $primary;
            }
          }
        }
      }
    }

    .body {
      min-height: 100px;
    }
  }
}
</style>
