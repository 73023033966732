<template>
  <base-detail-dialog :on-detail="getDetail" :on-save="save"></base-detail-dialog>
</template>
<script>
import { mediaDetail, mediaEditDetail } from "../api/media";
import BaseDetailDialog from "../../../base/components/Media/BaseDetailDialog";

export default {
  components: {BaseDetailDialog},
  methods: {
    getDetail(params) {
      return mediaDetail(params)
    },
    save(params) {
      return mediaEditDetail(params)
    }
  },
};
</script>
<style lang="scss" scoped>

</style>
