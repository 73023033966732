<template>
  <div class="media-view">
    <!-- 多选操作 -->
    <div class="fast-btn">
      <div class="fast-btn-left flex-center">
        <div class="overview-text">共{{total}}个文件</div>
        <div class="overview-text">
          <el-checkbox v-model="selectAll" :indeterminate="isIndeterminate" :disabled="!data.length" @change="handleCheckAllChange">全选</el-checkbox>
        </div>
        <!-- 添加到，目前先保持移动到 -->
        <confirm-popover width="367px" :disabled="!selectArr.length" :on-confirm="saveMove">
          <el-form-item>
            <div class="label-list">
              <media-label :title="f.title" size="small" v-for="(f) in availableFolders" :key="f.id"
                :active="popover.active === f.id" @click="popover.active = f.id"></media-label>
            </div>
          </el-form-item>
          <el-form-item>
            <el-button v-if="!newFolder.show" type="text" @click="newFolder.show = true">+新建分组</el-button>
            <div v-else class="flex">
              <el-input v-if="newFolder.show" maxlength="10" v-model="newFolder.title" show-word-limit
                v-focus></el-input>
              <el-button type="text" @click="saveNewFolder">完成</el-button>
              <el-button type="text" @click="newFolder.show = false">取消</el-button>
            </div>
          </el-form-item>
          <!-- Note: 先检查用户是否选择了文件 -->
          <div slot="reference" class="overview-text cursor-pointer" @click="moveMediaFile">移动到</div>
        </confirm-popover>
        <div class="overview-text cursor-pointer" @click="pluralDelete">删除</div>
      </div>
      <!-- 缩略图&列表 -->
      <!--      <div class="fast-btn-right btn-group">-->
      <!--        <el-button-->
      <!--          size="mini"-->
      <!--          :icon="`fonts ${!showType?'el-icon-menu':'el-icon-s-unfold'}`"-->
      <!--          class="switch-btn"-->
      <!--          @click="switchShowType"-->
      <!--        ></el-button>-->
      <!--      </div>-->
    </div>
    <!-- 分组相关操作 -->
    <div class="label-control flex-between">
      <div class="label-control-left flex-center">
        <!-- 滚动到前面的分组 -->
        <div class="swiper-button-prev swiper-button-black"></div>
        <!-- 分组列表 -->
        <swiper ref="mySwiper" :options="swiperOption" class="label-control-list">
          <swiper-slide v-for="(f, i) in folderOptions" :key="f.id" style="width: auto;">
            <media-label class="scroll-label-item" :title="f.title" :active="f.id === currentFolder.id"
              @click="handleClickLabel(f, i)"></media-label>
          </swiper-slide>
        </swiper>
        <!-- 滚动到后面的分组 -->
        <div class="swiper-button-next swiper-button-black"></div>
      </div>
      <div class="label-control-right flex">
        <!-- 编辑分组 -->
        <confirm-popover ref="editFolder" v-if="currentFolder.id !== -1" :on-confirm="saveEditFolderName"
          @show="popover.title = currentFolder.title">
          <i slot="reference" class="el-icon-edit-outline"></i>
          <el-form-item label="分组名称">
            <el-input maxlength="10" v-model="popover.title" v-focus show-word-limit
              @keyup.enter.native="pressEditEnter"></el-input>
          </el-form-item>
        </confirm-popover>
        <!-- 删除分组，数据移动到全部 -->
        <confirm-popover v-if="currentFolder.id !== -1" :on-confirm="deleteFolder">
          <i slot="reference" class="el-icon-delete"></i>
          <el-form-item label="">
            <div style="font-size: 12px; color: #303133; line-height: 18px;">
              仅删除分组，不删除图片，组内图片将自动归入全部
            </div>
          </el-form-item>
        </confirm-popover>
        <!-- 新建分组 -->
        <confirm-popover ref="newFolder" :on-confirm="saveNewFolderPopover">
          <el-form-item label="分组名称">
            <el-input maxlength="10" v-model="popover.title" v-focus show-word-limit
              @keyup.enter.native="pressEnter"></el-input>
          </el-form-item>
          <el-button slot="reference" type="text">+新建分组</el-button>
        </confirm-popover>
      </div>
    </div>
    <!-- 列表内容 -->
    <div class="content">
      <template v-if="data.length">
        <transition name="el-fade-in-linear" mode="out-in">
          <!-- 缩略图 -->
          <ViewThumbnail v-if="showType" :viewData="data" :selectArr.sync="selectArr" />
          <!-- 列表 -->
          <ViewTable v-else :viewData="data" :selectArr.sync="selectArr" />
        </transition>
      </template>
      <!-- 无内容占位 -->
      <div v-else class="placeholder">暂无内容...</div>
    </div>
    <!-- 视频弹窗 -->
    <VideoDialog />
    <!-- 音频弹窗 -->
    <MusicDialog />
    <!-- 移动弹窗 -->
    <!-- <MoveDialog/> -->
    <!-- 详情弹窗 -->
    <DetailDialog />
    <!-- 裁剪弹窗 -->
    <CropDialog />
    <!-- 新建文件夹弹窗 -->
    <!-- <CreateFolderDialog v-model="openCreateFolderDialog" :pid="0" />-->
  </div>
</template>
<script>
import ViewThumbnail from "./ViewThumbnail";
import ViewTable from "./ViewTable";
import {
  mediaCreateFolder,
  mediaDel,
  mediaEditDetail,
  mediaMove,
} from "../api/media";
import VideoDialog from "../../../base/components/Preview/VideoViewer"; // 视频弹窗
import MusicDialog from "../../../base/components/Preview/AudioViewer"; // 音频弹窗
// import MoveDialog from "./MoveDialog"; // 移动弹窗
import DetailDialog from "./DetailDialog"; // 移动弹窗
import CropDialog from "../../../base/components/Media/CropDialog"; // 裁剪弹窗
// import CreateFolderDialog from "./CreateFolderDialog";
import MediaLabel from "../../../base/components/Media/MediaLabel";
import ConfirmPopover from "../../../base/components/Default/ConfirmPopover";

import { createNamespacedHelpers } from "vuex";
const { mapGetters } = createNamespacedHelpers("media");

import { swiper, swiperSlide } from "vue-awesome-swiper";
import "swiper/dist/css/swiper.css";

// Vue 混入
import view from "../mixins/view";

export default {
  components: {
    ConfirmPopover,
    MediaLabel,
    ViewThumbnail,
    ViewTable,
    VideoDialog,
    MusicDialog,
    // MoveDialog,
    DetailDialog,
    CropDialog,
    // CreateFolderDialog,
    swiper,
    swiperSlide,
  },
  // 为了使用enterFolder
  mixins: [view],
  props: {
    data: {}, // Array
    total: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      selectAll: false, // 全选
      selectArr: [], // 选中项
      isIndeterminate: false,
      // openCreateFolderDialog: false, // 新建文件夹弹窗
      // 通用的popover弹窗变量
      popover: {
        // 移动（添加）到
        active: -1,
        // 编辑、新建
        title: "",
      },
      // 添加到pop中的新建分组
      newFolder: {
        show: false,
        title: "",
      },
      swiperOption: {
        spaceBetween: 0,
        slidesPerView: "auto",
        // Swiper5用法
        navigation: {
          // 如果需要前进后退按钮
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },
    };
  },
  computed: {
    ...mapGetters(["showType", "currentFolder"]),
    folderOptions: function () {
      // 过滤掉"根目录"
      return this.$store.state.media.folderOptions.filter(
        (item) => item.id !== 0
      );
    },
    availableFolders() {
      return this.$store.state.media.folderOptions.filter((item) => {
        return item.id > 0 && item.id !== this.currentFolder.id;
      });
    },
  },
  watch: {
    data(val) {
      // 数据更新取消全选
      if (this.selectArr.length) {
        this.selectAll = false;
        this.selectArr = [];
        this.isIndeterminate = false
      }
    },
    'selectArr.length': function (val) {
      this.selectAll = val === this.data.length;
      this.isIndeterminate = this.selectAll ? false : (val > 0 && val < this.data.length)
    }
  },
  methods: {
    pressEnter() {
      this.$refs.newFolder.confirm();
    },
    pressEditEnter() {
      this.$refs.editFolder.confirm();
    },
    // 其实也是删除文件
    deleteFolder() {
      return mediaDel({ ids: [this.currentFolder.id] })
        .then((res) => {
          // 更新选中的目录
          this.$store.commit("media/setCurrentFolder", {
            id: -1,
            title: "全部",
          });
          // 更新列表数据
          this.$store.commit("media/setIsRefresh", true);
          // 提示
          this.$message({
            type: "success",
            message: "删除成功!",
          });
          // 更新分组数据
          this.$store.dispatch("media/getFoldersData", true);
        })
        .catch((err) => {});
    },
    handleClickLabel(f, i) {
      // 轮播跳转到当前位置
      this.$refs.mySwiper.swiper.slideTo(i, 0);
      this.enterFolder({ ...f, type: "folder" });
    },
    saveMove() {
      return mediaMove({
        pid: this.popover.active,
        ids: this.selectArr,
      })
        .then((res) => {
          // this.$store.commit('media/setIsOpenMediaMoveDialog', false)
          this.popover.active = -1;
          // 更新
          this.$store.commit("media/setIsRefresh", true);
        })
        .catch((err) => {});
    },
    /**
     * "添加到" 里面的新建分组
     */
    saveNewFolder() {
      mediaCreateFolder({
        title: this.newFolder.title,
        pid: 0,
      })
        .then((res) => {
          // 更新分组数据
          this.$store.dispatch("media/getFoldersData", true);
          this.newFolder.title = "";
          this.popover.active = parseInt(res.data.id);
        })
        .catch((err) => {});
    },
    saveNewFolderPopover() {
      return mediaCreateFolder({
        title: this.popover.title,
        pid: 0,
      })
        .then((res) => {
          // 更新分组数据
          this.$store.dispatch("media/getFoldersData", true);
          this.popover.title = "";
        })
        .catch((err) => {});
    },
    saveEditFolderName() {
      return mediaEditDetail({
        id: this.currentFolder.id,
        title: this.popover.title,
      })
        .then((res) => {
          // 更新分组数据
          this.$store.dispatch("media/getFoldersData", true);
          this.popover.title = "";
        })
        .catch((err) => {});
    },
    // 新建文件夹
    // showCreateFolder() {
    //   this.openCreateFolderDialog = true;
    // },
    // 全选
    handleCheckAllChange(val) {
      let arr = [];
      if (val) {
        this.data.forEach((item) => {
          arr.push(item.id);
        });
      }
      this.selectArr = arr;
    },
    // 删除
    pluralDelete() {
      if (!this.selectArr.length) {
        this.$message.info("请勾选文件后再进行操作");
      } else {
        this.$msgbox
          .confirm(`您是否要删除勾选的 ${this.selectArr.length} 个文件？`, "提示", {
            type: "warning",
            confirmButtonText: "确定",
            cancelButtonText: "取消",
          })
          .then((res) => {
            this.$emit("loading", true);
            mediaDel({ ids: this.selectArr }).then((res) => {
              // 更新
              this.$store.commit("media/setIsRefresh", true);
              // 提示
              this.$message({
                type: "success",
                message: "删除成功!",
              });
            });
          })
          .catch((err) => {
            // console.log(err);
          });
      }
    },
    // 显示模式切换
    switchShowType() {
      this.$store.commit("media/setShowType", !this.showType);
    },
    // 移动文件
    moveMediaFile() {
      if (!this.selectArr.length) {
        this.$message.info("请勾选文件后再进行操作");
      }
      // this.$store.dispatch('media/openMediaMoveDialog', this.selectArr)
    },
  },
};
</script>
<style lang="scss" scoped>
.media-view {
  .fast-btn {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 12px;
    margin-bottom: 12px;

    .fast-btn-left {
      .overview-text {
        font-size: 14px;
        color: #303133;
        line-height: 14px;
        margin-right: 30px;
      }
    }

    .btn-group {
      button {
        & + button {
          margin-left: 15px;
        }

        ::v-deep .fonts {
          font-size: 12px;
        }

        &.switch-btn {
          padding: 7px;
        }
      }
    }
  }

  .label-control {
    margin-bottom: 12px;

    .label-control-left {
      min-width: calc(100% - 200px);
      padding: 0 34px;
      position: relative;
      --swiper-navigation-size: 14px;

      .label-control-list {
        width: 100%;
      }

      .swiper-button-next,
      .swiper-button-prev {
        width: 22px;
        outline: none;
        background-size: 8px;
      }
    }

    .label-control-right {
      width: 200px;
      justify-content: flex-end;
      align-items: center;

      .el-icon-edit-outline,
      .el-icon-delete {
        font-size: 28px;
        color: #606266;
        cursor: pointer;
      }

      .el-icon-delete,
      .el-button {
        margin-left: 36px;
      }
    }
  }

  @media (min-width: 1400px) {
    .label-control-left {
      min-width: calc(100% - 370px) !important;
    }

    .label-control-right {
      padding-left: 170px;
      min-width: 370px;
    }
  }

  .content {
    .placeholder {
      text-align: center;
      font-size: 14px;
      line-height: 40px;
    }
  }
}

.label-list {
  .media-label {
    margin-right: 10px;
  }
}
</style>
