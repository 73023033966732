<template>
  <el-dialog class="dialog-vertical"
             title=""
             :visible="open"
             @close="close">
    <div class="el-dialog audio-card">
      <div class="play-bg flex-center">
        <i v-if="loading" class="load-icon el-icon-loading"></i>
        <img v-else
             class="play-icon cursor-pointer"
             :src="playing ? pauseIcon : playIcon"
             @click="toggleStatus"/>
      </div>
      <div class="play-progress">
        <div class="audio-title">{{detail.title}}</div>
        <div class="flex-between">
          <el-slider v-model="current"
                     :disabled="loading"
                     :show-tooltip="false"
                     :min="0" :max="duration"
                     @change="handleChange"></el-slider>
          <div class="timer">{{currentText}} / {{durationText}}</div>
        </div>
      </div>
    </div>
    <audio :id="idString"
           :src="detail.url"
           @loadedmetadata="loadAudio"
           @ended="handleEnd"
           @pause="handlePause"
           @playing="handlePlaying"
           @timeupdate="updateProgress"></audio>
  </el-dialog>
</template>

<script>
  export default {
    name: "AudioViewer",
    data() {
      return {
        loading: true,
        playing: false,
        pauseIcon: require('@/base/assets/images/media/video-pause@2x.png'),
        playIcon: require('@/base/assets/images/media/video-play@2x.png'),
        current: 0,
        duration: 0,
        durationText: '00:00',
        idString: 'audio-preview'
      }
    },
    computed: {
      open: function () {
        return this.$store.state.media.isOpenMediaMusicDialog;
      },
      detail: function () {
        return this.$store.state.media.dialogDetail;
      },
      currentText: function () {
        return this.translateTime(this.current);
      },
      lastAudioIdString: function () {
        return this.$store.getters.lastAudioId;
      },
    },
    watch: {
      'detail.url' (val) {
        // console.log(val)
      }
    },
    methods: {
      /* 拖动改变进度条 */
      handleChange() {
        let isPlayJust = false
        if (!this._audioEl.paused) {
          isPlayJust = true;
          this.toggleStatus()
        }
        this._audioEl.currentTime = this.current;
        if (isPlayJust) {
          this.toggleStatus()
        }
      },
      /* 切换播放、暂停状态 */
      toggleStatus() {
        if (this._audioEl.paused) {
          // 暂停上一个正在播放的音频
          let lastPlaying = this.lastAudioIdString;
          if (lastPlaying && lastPlaying !== this.idString) {
            let ctx = document.querySelector('#' + lastPlaying)
            if (ctx && !ctx.paused) {
              ctx.pause();
            }
          }
          // 播放当前音频
          this._audioEl.play();
          // 记录正在播放的音频id
          this.$store.commit('setLastAudioId', this.idString)
        } else {
          this._audioEl.pause();
        }
      },
      handlePlaying() {
        // console.log('playing')
        this.playing = true;
      },
      /* 先触发pause事件 */
      handleEnd() {
        // console.log('ended')
        this._audioEl.currentTime = 0;
        this.current = 0;
      },
      handlePause() {
        // console.log('paused')
        this.playing = false;
      },
      /* 更新进度 */
      updateProgress() {
        if (this._audioEl?.currentTime) {
          this.current = Math.ceil(this._audioEl.currentTime);
        }
      },
      /* 获取音频时长 */
      loadAudio() {
        // console.log('load')
        if (!this._audioEl) {
          this._audioEl = document.querySelector('#' + this.idString);
        }
        if (this._audioEl?.duration) {
          this.duration = Math.ceil(this._audioEl.duration);
          this.durationText = this.translateTime(this.duration);
          this.loading = false;
          this.toggleStatus()
        }
      },
      /**
       * 秒 转 00:00 格式
       * @param{Number} t   second
       * @returns {string}
       */
      translateTime(t) {
        var duration = parseInt(t);
        var minute = parseInt(duration / 60 + '');
        var sec = duration % 60 + '';
        var isM0 = ':';
        if (minute === 0) {
          minute = '00';
        } else if (minute < 10) {
          minute = '0' + minute;
        }
        if (sec.length === 1) {
          sec = '0' + sec;
        }
        return minute + isM0 + sec;
      },
      close() {
        this.loading = true;
        this.duration = 0;
        this.durationText = '00:00';
        this.current = 0;
        if (this._audioEl && !this._audioEl.paused) {
          this.toggleStatus()
        }
        this.$store.commit('media/setDialogDetail', {})
        this.$store.commit('media/setIsOpenMediaMusicDialog', false)
      },
    }
  }
</script>

<style lang="scss" scoped>
  .dialog-vertical {
    & > .el-dialog,
    & > ::v-deep .el-dialog {
      background: unset;
      box-shadow: unset;
      width: 760px;

      .el-dialog__headerbtn {
        top: 0;

        .el-dialog__close {
          color: white;
        }
      }
    }
  }

  .audio-card {
    width: 622px;
    height: 180px;
    padding: 40px 26px;
    display: flex;

    .play-bg {
      width: 100px;
      height: 100px;
      min-width: 100px;
      background-color: #F8FAFF;
      margin-right: 20px;

      .play-icon {
        width: 28px;
      }

      .load-icon {
        font-size: 28px;
      }
    }

    .play-progress {
      width: calc(100% - 120px);

      .audio-title {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-bottom: 27px;
        font-size: 16px;
        color: #303133;
        line-height: 22px;
      }

      .el-slider {
        width: 100%;
      }

      .timer {
        white-space: nowrap;
        margin-left: 20px;
      }
    }
  }
</style>
