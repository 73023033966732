<template>
  <el-dialog
    :visible="open"
    :show-close="false"
    :close-on-click-modal="!status"
    :close-on-press-escape="!status"
    width="490px"
    title="资源详情"
    class="detail-dialog dialog-vertical"
    @close="close('mediaDetailForm')"
  >
    <div class="content" v-loading="loading">
      <el-form
        v-if="detailData"
        :model="detailData"
        :rules="rules"
        ref="mediaDetailForm"
        label-width="90px"
        size="small"
        label-position="left"
        @submit.native.prevent
      >
        <el-form-item label="文件名：" prop="title">
          <el-input v-model="detailData.title"></el-input>
        </el-form-item>
        <el-form-item label="上传者：">
          <span class="value">{{detailData.owner_name}}</span>
        </el-form-item>
        <el-form-item label="上传日期：">
          <span class="value">{{detailData.create_time}}</span>
        </el-form-item>
        <el-form-item label="大小：">
          <span class="value">{{detailData.file_size}}</span>
        </el-form-item>
        <el-form-item label="地址：">
          <el-input
            type="textarea"
            v-model="detailData.url"
            class="url"
            readonly
            ref="mediaDetailUrl"
          ></el-input>
        </el-form-item>
        <el-form-item label="描述：">
          <el-input type="textarea" v-model="detailData.description"></el-input>
        </el-form-item>
      </el-form>
    </div>
    <template #footer>
      <el-button size="small" @click="$store.commit('media/setShowDetail', false)">关闭</el-button>
      <el-button @click="cut()" size="small" type="success">裁剪</el-button>
      <el-button type="warning" size="small" @click="copy('mediaDetailUrl')">复制资源地址</el-button>
      <el-button type="primary" size="small" :loading="status" @click="save('mediaDetailForm')">
        <span v-if="!status">保存</span>
        <span v-else>保存中</span>
      </el-button>
    </template>
  </el-dialog>
</template>

<script>
  import {copyText} from "../../utils/tool";
  import regs from "../../utils/regs";

  export default {
    name: "BaseDetailDialog",
    props: {
      onDetail: Function,
      onSave: Function
    },
    data() {
      return {
        loading: false, //加载
        status: false, //状态
        detailData: null, //详情数据
        rules: {
          title: [
            {required: true, message: "请输入文件名", trigger: "blur"},
            {validator: regs.notSpace("文件名"), trigger: "blur"},
          ],
        },
      }
    },
    computed: {
      open() {
        return this.$store.state.media.showDetail;
      },
      detailId() {
        return this.$store.state.media.detailId;
      }
    },
    watch: {
      open(val) {
        if (val) {
          // 获取详情数据
          this.getMediaDetail({id: this.detailId});
        }
      }
    },
    methods: {
      cut() {
        this.$store.dispatch("crop/openMediaCropDialog", { imgSrc: this.detailData.url, mediaKey: '' });
      },
      // 获取详情
      getMediaDetail(params) {
        this.loading = true;
        this.onDetail(params)
          .then((res) => {
            const {data} = res;
            this.detailData = data;
            this.loading = false;
          })
          .catch((err) => {
            this.loading = false;
          });
      },
      close(formName) {
        this.$store.commit('media/setShowDetail', false)
        this.$store.commit('media/setDetailId', null)

        this.detailData = null;
        this.resetForm(formName);
      },
      resetForm(formName) {
        this.$refs[formName] && this.$refs[formName].resetFields();
      },
      // 复制
      copy(refName) {
        if (!this.detailData.url) {
          this.$message.warning("没有需要复制的内容");
          return;
        }
        const urlEle = this.$refs[refName]; // 用 $el 会多一个换行符结尾
        copyText(this.detailData.url || urlEle.$el).then((valid) => {
          this.$message.success("复制成功");
        });
      },
      // 保存
      save(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.status = true;
            this.onSave({
              id: this.detailId,
              title: this.detailData.title,
              description: this.detailData.description
            }).then((res) => {
              this.$message.success(
                `${this.detailData.title} 资源详情保存成功`
              );
              this.status = false;
              this.close(formName);
              // 更新
              this.$store.commit('media/setIsRefresh', true)
            })
              .catch((err) => {
                this.status = false;
              });
          }
        });
      },
    }
  }
</script>

<style lang="scss" scoped>
  .detail-dialog {
    ::v-deep.el-dialog__body {
      padding-top: 10px;
    }
    .content {
      min-height: 300px;
      .value {
        color: $info;
      }
      .el-form-item:last-child {
        margin-bottom: 0;
      }
      .btn-group {
        text-align: right;
      }
      .url {
        ::v-deeptextarea {
          background-color: #e9ecef;
          resize: none;
        }
      }
    }
  }
</style>
